<template>
  <div class="card">
    <div class="card-title">
      <div class="card-img-title">
        <img src="@/assets/icon/icon_e7fbmzzmou9/bangzhu0.png" style="width:20px;height:auto" alt="" />
        <span class="title"> {{ 'Q&A' }}</span>
      </div>
      <div>
        <a-icon type="reload" @click="getQuestionList" />
      </div>
    </div>
    <a-spin :spinning="loading">
      <div class="card-content">
        <div>
          <a-input-search
            allowClear
            v-model="searchQuestion"
            placeholder="请输入关键词"
            style="width: 100%"
            @search="onSearch"
          />
        </div>
        <div class="question-list">
          <div>常见问题列表：</div>
          <div class="question" @click="questionClick" v-for="(item, index) in questionList" :key="index">
            {{ item.title }}
          </div>
        </div>
      </div>
    </a-spin>
    <a-modal class="question-modal" :visible="visible" :footer="null" :closable="false" @cancel="handleCancel">
      <a-input-search
        allowClear
        v-model="searchQuestion"
        placeholder="请输入关键词"
        style="width: 100%"
        @change="searChValue"
      />
      <div class="answer-list" v-if="answerList.length">
        <div class="ant-popover-arrow" style="left: 15px;top: -3px;border: #fff;"></div>
        <div style="  max-height: 600px;overflow-y: auto;">
          <template v-if="searchQuestion">
            <div class="answer" v-for="(item, index) in answerList" :key="index">
              <div class="answer-title">
                <span v-if="item.title.indexOf(searchQuestion) > -1">
                  {{ item.title.substr(0, item.title.indexOf(searchQuestion)) }}
                  <span :style="'color:' + color">{{ searchQuestion }}</span>
                  {{ item.title.substr(item.title.indexOf(searchQuestion) + searchQuestion.length) }}
                </span>
                <span v-else>{{ item.title }}</span>
              </div>
              <div>
                {{ item.content }}
              </div>
            </div>
          </template>
          <template v-else>
            <div class="answer" v-for="(item, index) in questionList" :key="index">
              <div class="answer-title">
                <span v-if="item.title.indexOf(searchQuestion) > -1">
                  {{ item.title.substr(0, item.title.indexOf(searchQuestion)) }}
                  <span :style="'color:' + color">{{ searchQuestion }}</span>
                  {{ item.title.substr(item.title.indexOf(searchQuestion) + searchQuestion.length) }}
                </span>
                <span v-else>{{ item.title }}</span>
              </div>
              <div>
                {{ item.content }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getAction } from '@/api/manage'
import { simpleDebounce } from '@/utils/util'
export default {
  data() {
    return {
      searchQuestion: '',
      answerList: [],
      visible: false,
      color: '',
      questionList: [],
      loading: false
    }
  },
  created() {
    this.getQuestionList()
  },
  mounted() {
    this.color = this.$store.state.app.color
  },
  methods: {
    async getQuestionList() {
      this.loading = true
      const res = await getAction('/help/page')
      if (res.code == 200) {
        this.questionList = []
        let num = Math.floor(Math.random() * res.data.records.length)
        res.data.records?.forEach((item, index) => {
          if (num + 1 > res.data.records.length - 1) {
            if (index == num || index == num - 1) {
              this.questionList.push(item)
            }
          } else {
            if (index == num || index == num + 1) {
              this.questionList.push(item)
            }
          }
        })
        this.loading = false
      } else {
        this.$message.error(res.msg || res.message)
      }
    },
    handleCancel() {
      this.visible = false
    },
    searChValue: simpleDebounce(async function() {
      this.onSearch()
    }, 200),
    async onSearch() {
      if (!this.searchQuestion) return
      const res = await getAction('/help/page?title=' + this.searchQuestion)
      if (res.code == 200) {
        this.answerList = res.data.records
        this.visible = true
        if (!this.answerList.length) {
          this.answerList = [
            {
              title: '暂无搜索结果，请及时反馈',
              content: ''
            }
          ]
        }
      } else {
        this.$message.error(res.msg)
        this.visible = false
      }
    },
    questionClick(e) {
      this.searchQuestion = e.target.innerText
      this.onSearch()
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@assets/less/homeStyle.less';
.ant-popover-arrow {
  background: #fff !important;
}
.card-content {
  height: 100%;
  text-align: left;
  overflow: hidden;
  position: relative;
}
.question-list {
  font-size: 12px;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.25);
  > div {
    height: 21px;
    line-height: 21px;
  }
  .question {
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      color: var(--theme-color);
    }
  }
}
.answer-list {
  background-color: #fff;
  margin-top: 15px;
  border-radius: 4px;
  position: relative;
  padding: 25px;
  .answer {
    background-color: #f3f3f3;
    padding: 15px;
    margin-top: 25px;
    line-height: 25px;
    border-radius: 4px;
    &:nth-child(1) {
      margin-top: 0 !important;
    }
    .answer-title {
      font-weight: 700;
    }
  }
  div::-webkit-scrollbar-button {
    height: 0;
  }
  div::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
  }
  div::-webkit-scrollbar-track {
    border-radius: 0;
  }
  div::-webkit-scrollbar-track-piece {
    border-radius: 0;
  }
  div::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #c1c1c1;
  }
}
</style>
