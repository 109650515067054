var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("div", { staticClass: "card-title" }, [
        _c("div", { staticClass: "card-img-title" }, [
          _c("img", {
            staticStyle: { width: "20px", height: "auto" },
            attrs: {
              src: require("@/assets/icon/icon_e7fbmzzmou9/bangzhu0.png"),
              alt: "",
            },
          }),
          _c("span", { staticClass: "title" }, [_vm._v(" " + _vm._s("Q&A"))]),
        ]),
        _c(
          "div",
          [
            _c("a-icon", {
              attrs: { type: "reload" },
              on: { click: _vm.getQuestionList },
            }),
          ],
          1
        ),
      ]),
      _c("a-spin", { attrs: { spinning: _vm.loading } }, [
        _c("div", { staticClass: "card-content" }, [
          _c(
            "div",
            [
              _c("a-input-search", {
                staticStyle: { width: "100%" },
                attrs: { allowClear: "", placeholder: "请输入关键词" },
                on: { search: _vm.onSearch },
                model: {
                  value: _vm.searchQuestion,
                  callback: function ($$v) {
                    _vm.searchQuestion = $$v
                  },
                  expression: "searchQuestion",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "question-list" },
            [
              _c("div", [_vm._v("常见问题列表：")]),
              _vm._l(_vm.questionList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "question",
                    on: { click: _vm.questionClick },
                  },
                  [_vm._v("\n          " + _vm._s(item.title) + "\n        ")]
                )
              }),
            ],
            2
          ),
        ]),
      ]),
      _c(
        "a-modal",
        {
          staticClass: "question-modal",
          attrs: { visible: _vm.visible, footer: null, closable: false },
          on: { cancel: _vm.handleCancel },
        },
        [
          _c("a-input-search", {
            staticStyle: { width: "100%" },
            attrs: { allowClear: "", placeholder: "请输入关键词" },
            on: { change: _vm.searChValue },
            model: {
              value: _vm.searchQuestion,
              callback: function ($$v) {
                _vm.searchQuestion = $$v
              },
              expression: "searchQuestion",
            },
          }),
          _vm.answerList.length
            ? _c("div", { staticClass: "answer-list" }, [
                _c("div", {
                  staticClass: "ant-popover-arrow",
                  staticStyle: { left: "15px", top: "-3px", border: "#fff" },
                }),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "max-height": "600px",
                      "overflow-y": "auto",
                    },
                  },
                  [
                    _vm.searchQuestion
                      ? _vm._l(_vm.answerList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "answer" },
                            [
                              _c("div", { staticClass: "answer-title" }, [
                                item.title.indexOf(_vm.searchQuestion) > -1
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            item.title.substr(
                                              0,
                                              item.title.indexOf(
                                                _vm.searchQuestion
                                              )
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                      _c(
                                        "span",
                                        { style: "color:" + _vm.color },
                                        [_vm._v(_vm._s(_vm.searchQuestion))]
                                      ),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            item.title.substr(
                                              item.title.indexOf(
                                                _vm.searchQuestion
                                              ) + _vm.searchQuestion.length
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ])
                                  : _c("span", [_vm._v(_vm._s(item.title))]),
                              ]),
                              _c("div", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.content) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          )
                        })
                      : _vm._l(_vm.questionList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "answer" },
                            [
                              _c("div", { staticClass: "answer-title" }, [
                                item.title.indexOf(_vm.searchQuestion) > -1
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            item.title.substr(
                                              0,
                                              item.title.indexOf(
                                                _vm.searchQuestion
                                              )
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                      _c(
                                        "span",
                                        { style: "color:" + _vm.color },
                                        [_vm._v(_vm._s(_vm.searchQuestion))]
                                      ),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            item.title.substr(
                                              item.title.indexOf(
                                                _vm.searchQuestion
                                              ) + _vm.searchQuestion.length
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ])
                                  : _c("span", [_vm._v(_vm._s(item.title))]),
                              ]),
                              _c("div", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.content) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          )
                        }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }